import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import Title from "../../components/title"
import BackButton from "../../components/back-button"

export const query =  graphql`
  query TEMPLATE_WP_POST_QUERY(
    $id: String!
  ) {
    wpPost(id: { eq: $id }) {
      ...postData
      ...postFeaturedImage
      categories {
        nodes {
          name
        }
      }
    }
  }
`

const wpPostTemplate = ({ data = {} }) => {
  const { wpPost: post = {} } = data
  const { 
    pageMeta = {},
    smartcrawlSeo = {} 
  } = post
  const { title: metaTitle = '', metaDesc = '' } = smartcrawlSeo
  const image = pageMeta.headerImage && convertToBgImage(getImage(pageMeta.headerImage.localFile))
 
  return (
    <React.Fragment>
      <Seo post={data.wpPost} />
      <main>
        {image &&
          <BackgroundImage 
            {...image} 
            className="header-image"
          />
        }
        
        <Title title={post.title} />

        <div className="container py-5">
          <div className="col-12 col-md-8 offset-md-2" dangerouslySetInnerHTML={{ __html: post.content }} />

          <BackButton/>
        </div>
      </main>
    </React.Fragment>
  )
}

export default wpPostTemplate