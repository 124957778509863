import React from "react"
import { navigate } from "gatsby"

const BackButton = ({}) => {
  return (
    <div className="d-flex justify-content-center">
      <button onClick={() => navigate(-1)} className="mt-5 d-inline-block btn btn-secondary btn-sm"> Go back</button>
    </div>
  )
}

export default BackButton